exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-article-viewer-js": () => import("./../../../src/pages/blog/article-viewer.js" /* webpackChunkName: "component---src-pages-blog-article-viewer-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-posts-js": () => import("./../../../src/pages/blog/posts.js" /* webpackChunkName: "component---src-pages-blog-posts-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-events-articles-article-viewer-js": () => import("./../../../src/pages/events-articles/article-viewer.js" /* webpackChunkName: "component---src-pages-events-articles-article-viewer-js" */),
  "component---src-pages-events-articles-events-js": () => import("./../../../src/pages/events-articles/events.js" /* webpackChunkName: "component---src-pages-events-articles-events-js" */),
  "component---src-pages-expertise-automotive-js": () => import("./../../../src/pages/expertise/automotive.js" /* webpackChunkName: "component---src-pages-expertise-automotive-js" */),
  "component---src-pages-expertise-financial-services-js": () => import("./../../../src/pages/expertise/financial-services.js" /* webpackChunkName: "component---src-pages-expertise-financial-services-js" */),
  "component---src-pages-expertise-healthcare-js": () => import("./../../../src/pages/expertise/healthcare.js" /* webpackChunkName: "component---src-pages-expertise-healthcare-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-expertise-logistics-js": () => import("./../../../src/pages/expertise/logistics.js" /* webpackChunkName: "component---src-pages-expertise-logistics-js" */),
  "component---src-pages-expertise-retail-js": () => import("./../../../src/pages/expertise/retail.js" /* webpackChunkName: "component---src-pages-expertise-retail-js" */),
  "component---src-pages-expertise-telecom-js": () => import("./../../../src/pages/expertise/telecom.js" /* webpackChunkName: "component---src-pages-expertise-telecom-js" */),
  "component---src-pages-find-developers-android-js": () => import("./../../../src/pages/find-developers/android.js" /* webpackChunkName: "component---src-pages-find-developers-android-js" */),
  "component---src-pages-find-developers-angular-js": () => import("./../../../src/pages/find-developers/angular.js" /* webpackChunkName: "component---src-pages-find-developers-angular-js" */),
  "component---src-pages-find-developers-back-end-js": () => import("./../../../src/pages/find-developers/back-end.js" /* webpackChunkName: "component---src-pages-find-developers-back-end-js" */),
  "component---src-pages-find-developers-dot-net-js": () => import("./../../../src/pages/find-developers/dot-net.js" /* webpackChunkName: "component---src-pages-find-developers-dot-net-js" */),
  "component---src-pages-find-developers-flutter-js": () => import("./../../../src/pages/find-developers/flutter.js" /* webpackChunkName: "component---src-pages-find-developers-flutter-js" */),
  "component---src-pages-find-developers-front-end-js": () => import("./../../../src/pages/find-developers/front-end.js" /* webpackChunkName: "component---src-pages-find-developers-front-end-js" */),
  "component---src-pages-find-developers-golang-js": () => import("./../../../src/pages/find-developers/golang.js" /* webpackChunkName: "component---src-pages-find-developers-golang-js" */),
  "component---src-pages-find-developers-ios-mobile-js": () => import("./../../../src/pages/find-developers/ios-mobile.js" /* webpackChunkName: "component---src-pages-find-developers-ios-mobile-js" */),
  "component---src-pages-find-developers-java-tech-js": () => import("./../../../src/pages/find-developers/java-tech.js" /* webpackChunkName: "component---src-pages-find-developers-java-tech-js" */),
  "component---src-pages-find-developers-js": () => import("./../../../src/pages/find-developers.js" /* webpackChunkName: "component---src-pages-find-developers-js" */),
  "component---src-pages-find-developers-mobile-js": () => import("./../../../src/pages/find-developers/mobile.js" /* webpackChunkName: "component---src-pages-find-developers-mobile-js" */),
  "component---src-pages-find-developers-node-js-js": () => import("./../../../src/pages/find-developers/node-js.js" /* webpackChunkName: "component---src-pages-find-developers-node-js-js" */),
  "component---src-pages-find-developers-php-js": () => import("./../../../src/pages/find-developers/php.js" /* webpackChunkName: "component---src-pages-find-developers-php-js" */),
  "component---src-pages-find-developers-python-js": () => import("./../../../src/pages/find-developers/python.js" /* webpackChunkName: "component---src-pages-find-developers-python-js" */),
  "component---src-pages-find-developers-react-js-js": () => import("./../../../src/pages/find-developers/react-js.js" /* webpackChunkName: "component---src-pages-find-developers-react-js-js" */),
  "component---src-pages-find-developers-react-native-js": () => import("./../../../src/pages/find-developers/react-native.js" /* webpackChunkName: "component---src-pages-find-developers-react-native-js" */),
  "component---src-pages-find-developers-ruby-js": () => import("./../../../src/pages/find-developers/ruby.js" /* webpackChunkName: "component---src-pages-find-developers-ruby-js" */),
  "component---src-pages-find-developers-rust-js": () => import("./../../../src/pages/find-developers/rust.js" /* webpackChunkName: "component---src-pages-find-developers-rust-js" */),
  "component---src-pages-find-developers-vue-js-js": () => import("./../../../src/pages/find-developers/vue-js.js" /* webpackChunkName: "component---src-pages-find-developers-vue-js-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-two-js": () => import("./../../../src/pages/indexTwo.js" /* webpackChunkName: "component---src-pages-index-two-js" */),
  "component---src-pages-new-services-js": () => import("./../../../src/pages/new-services.js" /* webpackChunkName: "component---src-pages-new-services-js" */),
  "component---src-pages-our-operations-model-index-js": () => import("./../../../src/pages/our-operations-model/index.js" /* webpackChunkName: "component---src-pages-our-operations-model-index-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rrhh-js": () => import("./../../../src/pages/rrhh.js" /* webpackChunkName: "component---src-pages-rrhh-js" */),
  "component---src-pages-services-business-analysis-js": () => import("./../../../src/pages/services/business-analysis.js" /* webpackChunkName: "component---src-pages-services-business-analysis-js" */),
  "component---src-pages-services-cross-platform-js": () => import("./../../../src/pages/services/cross-platform.js" /* webpackChunkName: "component---src-pages-services-cross-platform-js" */),
  "component---src-pages-services-design-services-api-program-js": () => import("./../../../src/pages/services/design-services/api-program.js" /* webpackChunkName: "component---src-pages-services-design-services-api-program-js" */),
  "component---src-pages-services-design-services-data-driven-js": () => import("./../../../src/pages/services/design-services/data-driven.js" /* webpackChunkName: "component---src-pages-services-design-services-data-driven-js" */),
  "component---src-pages-services-design-services-digital-architecture-js": () => import("./../../../src/pages/services/design-services/digital-architecture.js" /* webpackChunkName: "component---src-pages-services-design-services-digital-architecture-js" */),
  "component---src-pages-services-design-services-index-js": () => import("./../../../src/pages/services/design-services/index.js" /* webpackChunkName: "component---src-pages-services-design-services-index-js" */),
  "component---src-pages-services-design-services-open-finance-js": () => import("./../../../src/pages/services/design-services/open-finance.js" /* webpackChunkName: "component---src-pages-services-design-services-open-finance-js" */),
  "component---src-pages-services-development-services-bpo-js": () => import("./../../../src/pages/services/development-services/bpo.js" /* webpackChunkName: "component---src-pages-services-development-services-bpo-js" */),
  "component---src-pages-services-development-services-closed-project-js": () => import("./../../../src/pages/services/development-services/closed-project.js" /* webpackChunkName: "component---src-pages-services-development-services-closed-project-js" */),
  "component---src-pages-services-development-services-index-js": () => import("./../../../src/pages/services/development-services/index.js" /* webpackChunkName: "component---src-pages-services-development-services-index-js" */),
  "component---src-pages-services-development-services-mobile-development-js": () => import("./../../../src/pages/services/development-services/mobile-development.js" /* webpackChunkName: "component---src-pages-services-development-services-mobile-development-js" */),
  "component---src-pages-services-development-services-sap-js": () => import("./../../../src/pages/services/development-services/sap.js" /* webpackChunkName: "component---src-pages-services-development-services-sap-js" */),
  "component---src-pages-services-development-services-web-development-js": () => import("./../../../src/pages/services/development-services/web-development.js" /* webpackChunkName: "component---src-pages-services-development-services-web-development-js" */),
  "component---src-pages-services-devsecops-js": () => import("./../../../src/pages/services/devsecops.js" /* webpackChunkName: "component---src-pages-services-devsecops-js" */),
  "component---src-pages-services-digital-talent-hunting-js": () => import("./../../../src/pages/services/digital-talent/hunting.js" /* webpackChunkName: "component---src-pages-services-digital-talent-hunting-js" */),
  "component---src-pages-services-digital-talent-index-js": () => import("./../../../src/pages/services/digital-talent/index.js" /* webpackChunkName: "component---src-pages-services-digital-talent-index-js" */),
  "component---src-pages-services-digital-talent-out-sourcing-js": () => import("./../../../src/pages/services/digital-talent/out-sourcing.js" /* webpackChunkName: "component---src-pages-services-digital-talent-out-sourcing-js" */),
  "component---src-pages-services-digital-talent-product-scope-js": () => import("./../../../src/pages/services/digital-talent/product-scope.js" /* webpackChunkName: "component---src-pages-services-digital-talent-product-scope-js" */),
  "component---src-pages-services-digital-talent-staffing-js": () => import("./../../../src/pages/services/digital-talent/staffing.js" /* webpackChunkName: "component---src-pages-services-digital-talent-staffing-js" */),
  "component---src-pages-services-engineering-services-cloud-development-js": () => import("./../../../src/pages/services/engineering-services/cloud-development.js" /* webpackChunkName: "component---src-pages-services-engineering-services-cloud-development-js" */),
  "component---src-pages-services-engineering-services-digital-factory-js": () => import("./../../../src/pages/services/engineering-services/digital-factory.js" /* webpackChunkName: "component---src-pages-services-engineering-services-digital-factory-js" */),
  "component---src-pages-services-engineering-services-digital-operation-js": () => import("./../../../src/pages/services/engineering-services/digital-operation.js" /* webpackChunkName: "component---src-pages-services-engineering-services-digital-operation-js" */),
  "component---src-pages-services-engineering-services-index-js": () => import("./../../../src/pages/services/engineering-services/index.js" /* webpackChunkName: "component---src-pages-services-engineering-services-index-js" */),
  "component---src-pages-services-engineering-services-solution-architecture-js": () => import("./../../../src/pages/services/engineering-services/solution-architecture.js" /* webpackChunkName: "component---src-pages-services-engineering-services-solution-architecture-js" */),
  "component---src-pages-services-it-security-js": () => import("./../../../src/pages/services/it-security.js" /* webpackChunkName: "component---src-pages-services-it-security-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-quality-assurance-js": () => import("./../../../src/pages/services/quality-assurance.js" /* webpackChunkName: "component---src-pages-services-quality-assurance-js" */),
  "component---src-pages-services-ui-ux-js": () => import("./../../../src/pages/services/ui-ux.js" /* webpackChunkName: "component---src-pages-services-ui-ux-js" */),
  "component---src-pages-software-development-services-js": () => import("./../../../src/pages/software-development-services.js" /* webpackChunkName: "component---src-pages-software-development-services-js" */),
  "component---src-pages-software-development-teams-js": () => import("./../../../src/pages/software-development-teams.js" /* webpackChunkName: "component---src-pages-software-development-teams-js" */),
  "component---src-pages-staff-augmentation-js": () => import("./../../../src/pages/staff-augmentation.js" /* webpackChunkName: "component---src-pages-staff-augmentation-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-tech-stack-js": () => import("./../../../src/pages/tech-stack.js" /* webpackChunkName: "component---src-pages-tech-stack-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-vision-mision-js": () => import("./../../../src/pages/vision-mision.js" /* webpackChunkName: "component---src-pages-vision-mision-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

